import React, { useState, useEffect } from 'react';
import { Layout, Menu, Spin } from "antd";
import { AppstoreOutlined, UserOutlined, LoadingOutlined, TrophyOutlined } from "@ant-design/icons";
import { FormularioCrearNoticia } from './FormCrearNoticia';
import { FormularioCrearPrograma } from './FormCrearPrograma';
import { FormularioCrearTrabajoGanador } from './FormCreateWork';
import { FormularioCrearVocesPueblos } from './FormCreateVocesPueblos';
import Cookies from "universal-cookie";
import Swal from 'sweetalert2';
import "./AdminDashboard.css";


const { Header, Content, Sider } = Layout;

const getItem = (label, key, icon, children) => {
  return { key, icon, children, label };
};

const items = [
  getItem("Noticias y programas", "sub2", <AppstoreOutlined />, [
      getItem("Nueva noticia", "1"),
      getItem("Nuevo programa", "2"),
      getItem("Nuevas voces", "3"),
  ]),
  getItem("Trabajos ganadores", "sub3", <TrophyOutlined />, [
    getItem("Nuevo trabajo ganador", "4"),
  ])
];



const AdminDashboard = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedKey, setSelectedKey] = useState("1");

    useEffect(() => {
      const cookies = new Cookies();
      const cookieRole = cookies.get("role");
      
      if (cookieRole !== "Admin") {
          Swal.fire({
              title: "Error!",
              text: "No es usuario Admin",
              icon: "error",
              confirmButtonText: "Ok",
          }).then(() => {
              window.location.href = "/home";
          });
      } else {
          setIsAdmin(true);
          setIsLoading(false);
      }
  }, []);
  
    const handleMenuClick = (e) => {
      setSelectedKey(e.key);
    };
  
    if (isLoading) {
      return (
        <div className="loading-container">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

  if (!isAdmin) {
      return null;
  }
  
    // const changeTheme = (value) => {
    //   setTheme(value ? "dark" : "light");
    // };
  


    return (
      <Layout className="admin-layout">
        <Header className="admin-header">
          <div className="logo">Admin Dashboard</div>
        </Header>
        <Layout>
          <Sider width={200} className="admin-sider">
            <Menu
              mode="inline"
              selectedKeys={[selectedKey]}
              onClick={handleMenuClick}
              style={{ height: "100%", borderRight: 0 }}
              items={items}
            />
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              className="admin-content"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              {selectedKey === "1" && <FormularioCrearNoticia />}
              {selectedKey === "2" && <FormularioCrearPrograma />}
              {selectedKey === "3" && <FormularioCrearVocesPueblos />}
              {selectedKey === "4" && <FormularioCrearTrabajoGanador />}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  };
  
  export default AdminDashboard;
