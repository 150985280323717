import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Navbar from './components/Navbar/Navbar.jsx';
import Footer from './components/Footer/Footer.jsx';
import Home from './views/Home/Home.jsx'
import Diario from './views/Diario/Diario.jsx'
import Radio from './views/Radio/Radio.jsx'
import Nosotros from './views/Nosotros/Nosotros.jsx'
import Contactanos from './views/Contactanos/Contactanos.jsx'
import Noticia from './views/Noticia/Noticia.jsx'
import Edicion from './views/Edicion/Edicion.jsx';
import Concurso from './views/Concurso/Concurso.jsx';
import Pueblos from './views/Pueblos/Pueblos.jsx';
import AdminLogin from './views/Admin/AdminLogin.jsx';
import AdminDashboard from './views/Admin/AdminDashboard.jsx';
import { FormularioCrearNoticia } from './views/Admin/FormCrearNoticia.jsx';
import { FormularioCrearVocesPueblos } from './views/Admin/FormCreateVocesPueblos.jsx';



const Layout = () => {
    const basename = process.env.BASENAME || '';
    const isAuthenticated = useSelector((state) => state.isAuthenticated);
  return (
    <div>
        <BrowserRouter basename={basename}>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/diario" element={<Diario />} />
                <Route path="/noticia/:id" element={<Noticia />} /> {/* nueva ruta */}
                <Route exact path="/radio" element={<Radio />} />
                <Route path="/AdminLogin" element={<AdminLogin />} />
                {isAuthenticated ? (
                <Route path="/admin-dashboard" element={<AdminDashboard />} />
                ) : (
                <Route path="/admin-dashboard" element={<Navigate to="/AdminLogin" />} />
                )}
                <Route exact path="/nosotros" element={<Nosotros />} />
                <Route exact path="/contactanos" element={<Contactanos />} />
                <Route exact path="/edicion" element={<Edicion />} />
                <Route exact path="/concurso" element={<Concurso />} />
                <Route exact path="/pueblos" element={<Pueblos />} />
                <Route path="/editar-noticia/:noticeId" element={<FormularioCrearNoticia />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    </div>
  )
}

export default Layout;