export const GET_NOTICES = 'GET_NOTICES';
export const POST_NEW_NOTICE = "POST_NEW_NOTICE";
export const GET_NOTICE = 'GET_NOTICE';
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const POST_NEW_PROGRAM = "POST_NEW_PROGRAM";
export const GET_PROGRAMS = "GET_PROGRAMS";
export const GET_PROGRAM = "GET_PROGRAM";
export const GET_WINNING_WORKS = "GET_WINNING_WORKS";
export const POST_NEW_WORK = "POST_NEW_WORK";
export const UPDATE_NOTICE = "UPDATE_NOTICE";
export const GET_PUEBLOS = 'GET_PUEBLOS';
export const GET_PUEBLO = "GET_PUEBLO";
export const POST_NEW_PUEBLO = "POST_NEW_PUEBLO";
export const UPDATE_PUEBLO = "UPDATE_PUEBLO";