import axios from "axios";
import {
    POST_NEW_NOTICE,
    POST_NEW_PROGRAM,
    POST_NEW_WORK,
    GET_NOTICES,
    GET_NOTICE,
    UPDATE_NOTICE,
    GET_PROGRAMS,
    GET_PROGRAM,
    GET_WINNING_WORKS,
    ADMIN_LOGIN_SUCCESS,
    GET_PUEBLOS,
    GET_PUEBLO,
    POST_NEW_PUEBLO,
    UPDATE_PUEBLO
} from "./Types";

import Swal from 'sweetalert2';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const postNewNotice = (payload) => {
    return async (dispatch) => {
        try {
            const response = await axios.post("/diario", payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    //'Authorization': `Bearer ${token}`
                }
            });
            dispatch({
                type: POST_NEW_NOTICE,
                payload: response.data,
            });

             // Mostrar una alerta de éxito
            Swal.fire({
                       title: '¡Éxito!',
                       text: 'La noticia se ha subido correctamente.',
                       icon: 'success',
                       confirmButtonText: 'OK'
                      });

        } catch (error) {
            console.error("Error posting notice:", error.response ? error.response.data : error.message);

        // Mostrar una alerta de error
            Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al subir la noticia.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                      });

            throw error; // Re-throw the error to be handled in the component if needed
        }
    };
};

export const updateNotice = (id, formData) => { 
    return async (dispatch) => { 
        try { 
            const response = await axios.put(`/diario/noticias/${id}`, formData, { 
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                } 
            }); 
            dispatch({ 
                type: UPDATE_NOTICE, 
                payload: response.data, }); 

            // Mostrar una alerta de éxito 
            Swal.fire({ 
                title: '¡Éxito!', 
                text: 'La noticia se ha actualizado correctamente.', 
                icon: 'success', 
                confirmButtonText: 'OK' 
            }); 
        } catch (error) { 
            console.error("Error updating notice:", error.response ? error.response.data : error.message); 
            
            // Mostrar una alerta de error 
            Swal.fire({ 
                title: 'Error', 
                text: 'Hubo un problema al actualizar la noticia.', 
                icon: 'error', 
                confirmButtonText: 'OK' 
            }); 
            
            throw error; // Re-throw the error to be handled in the component if needed 
        } 
    }; 
};

export const postNewProgram = (payload) => {
    return async (dispatch) => {
        try {
            const response = await axios.post("/radio", payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            dispatch({
                type: POST_NEW_PROGRAM,
                payload: response.data,
            });

                        // Mostrar una alerta de éxito
                        Swal.fire({
                            title: '¡Éxito!',
                            text: 'El programa de radio se ha subido correctamente.',
                            icon: 'success',
                            confirmButtonText: 'OK'
                        });

        } catch (error) {
            console.error("Error posting program:", error.response ? error.response.data : error.message);

                        // Mostrar una alerta de error
                        Swal.fire({
                            title: 'Error',
                            text: 'Hubo un problema al subir el programa.',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        });

            throw error; // Re-throw the error to be handled in the component if needed
        }
    };
};

export const postWinningWork = (payload) => {
    return async (dispatch) => {
        try {
            const response = await axios.post("/concurso", payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    //'Authorization': `Bearer ${token}`
                }
            });
            dispatch({
                type: POST_NEW_WORK,
                payload: response.data,
            });

             // Mostrar una alerta de éxito
            Swal.fire({
                       title: '¡Éxito!',
                       text: 'El trabajo se ha subido correctamente.',
                       icon: 'success',
                       confirmButtonText: 'OK'
                      });

        } catch (error) {
            console.error("Error posting notice:", error.response ? error.response.data : error.message);

        // Mostrar una alerta de error
            Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al subir el trabajo.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                      });

            throw error;
        }
    };
};

export const getWinningWorksByCategory = (category, subcategory) => {
    return async (dispatch) => {
        try {
            const response = await axios.get("/concurso", {
                params: { category, subcategory }
            });

            dispatch({
                type: GET_WINNING_WORKS,
                payload: response.data, // Se asume que `response.data` es la lista de trabajos ganadores
            });

            return response.data; // Devuelve la data para que el componente pueda manejarla
        } catch (error) {
            if (error.response && error.response.status === 404) {
                dispatch({
                    type:GET_WINNING_WORKS,
                    payload: [],
                });
                return []; // Devuelve un array vacío para que el componente pueda manejarlo
            }
            console.error("Error fetching winning works:", error.response ? error.response.data : error.message);

            // Mostrar una alerta de error
            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al obtener los trabajos ganadores.',
                icon: 'error',
                confirmButtonText: 'OK'
            });

            throw error; // Vuelve a lanzar el error para manejo en el componente si es necesario
        }
    };
};

export const getNotices = () => {
    return async function (dispatch) {
        try {
            const response = await fetch('/diario/noticias');
            const data = await response.json();
            console.log(data);
            dispatch({
                type: GET_NOTICES,
                payload: data,
            })
        } catch (error) {
            console.error(error);
        }
    }
}

export const getNotice = (id) => {
    try {
        return async function (dispatch) {
            let json = await axios.get(`/diario/noticias/${id}`);
            console.log("console.log", json)

            return dispatch({
                type: GET_NOTICE,
                payload: json.data,
            })
        }

    } catch (error) {
        console.log("error-post", error)

    }

}

export const getPrograms = () => {
    return async function (dispatch) {
        try {
            const response = await fetch('/radio/programas');
            const data = await response.json();
            console.log(data);
            dispatch({
                type: GET_PROGRAMS,
                payload: data,
            })
        } catch (error) {
            console.error(error);
        }
    }
}

export const getProgram = (id) => {
    try {
        return async function (dispatch) {
            let json = await axios.get(`/radio/programas/${id}`);

            return dispatch({
                type: GET_PROGRAM,
                payload: json.data,
            })
        }

    } catch (error) {
        console.log("error-post", error)

    }

}

export const getPueblos = () => {
    return async function (dispatch) {
        try {
            const response = await fetch('/pueblos');
            const data = await response.json();
            console.log(data);
            dispatch({
                type: GET_PUEBLOS,
                payload: data,
            })
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al obtener los pueblos.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    }
}

export const getPueblo = (id) => {
    try {
        return async function (dispatch) {
            let json = await axios.get(`/pueblos/voces/${id}`);
            console.log("console.log", json)

            return dispatch({
                type: GET_PUEBLO,
                payload: json.data,
            })
        }
    } catch (error) {
        console.log("error-post", error)
        Swal.fire({
            title: 'Error',
            text: 'Hubo un problema al obtener el pueblo.',
            icon: 'error',
            confirmButtonText: 'OK'
        });
    }
}

export const postNewPueblo = (payload) => {
    return async (dispatch) => {
        try {
            console.log("Payload enviado:", payload);
            const response = await axios.post("/pueblos", payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            dispatch({
                type: POST_NEW_PUEBLO,
                payload: response.data,
            });

            Swal.fire({
                title: '¡Éxito!',
                text: 'El pueblo se ha agregado correctamente.',
                icon: 'success',
                confirmButtonText: 'OK'
            });

        } catch (error) {
            console.error("Error posting pueblo:", error.response ? error.response.data : error.message);

            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al agregar el pueblo.',
                icon: 'error',
                confirmButtonText: 'OK'
            });

            throw error;
        }
    };
};

export const updatePueblo = (id, formData) => {
    return async (dispatch) => {
        try {
            const response = await axios.put(`/pueblos/voces/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            dispatch({
                type: UPDATE_PUEBLO,
                payload: response.data,
            });

            Swal.fire({
                title: '¡Éxito!',
                text: 'El pueblo se ha actualizado correctamente.',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } catch (error) {
            console.error("Error updating pueblo:", error.response ? error.response.data : error.message);

            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al actualizar el pueblo.',
                icon: 'error',
                confirmButtonText: 'OK'
            });

            throw error;
        }
    };
};

export const adminLogin = (username, password, navigate) => async (dispatch) => {
    try {
        const response = await axios.post('/user/login', { email: username, password });
        const { token, role } = response.data;

        // Store the token and role in cookies
        cookies.set('token', token, { path: '/' });
        cookies.set('role', role, { path: '/' });

        if (role === 'Admin') {
            dispatch({ type: ADMIN_LOGIN_SUCCESS });
            navigate('/admin-dashboard');
        } else {
            alert('No es usuario Admin');
        }
    } catch (error) {
        console.error("Error during authentication:", error);
        alert('Error en la autenticación');
    }
};