import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postNewPueblo, updatePueblo, getPueblos } from '../../Redux/Actions/Index';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Button, Upload, DatePicker } from 'antd';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";
import "./AdminDashboard.css";

function FormularioCrearVocesPueblos() {
    const dispatch = useDispatch();
    const { vocesPueblosId } = useParams();
    const vocesPueblos = useSelector(state => state.vocesPueblosDetail);

    const [fileList, setFileList] = useState([]);
    const [inputVocesPueblos, setInputVocesPueblos] = useState({
        title: "",
        description: "",
        date: null,
        link: ""
    });
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (vocesPueblosId) {
            setIsEditing(true);
            dispatch(getPueblos(vocesPueblosId));
        }
    }, [vocesPueblosId, dispatch]);

    useEffect(() => {
        if (isEditing && vocesPueblos) {
            setInputVocesPueblos({
                title: vocesPueblos.title,
                description: vocesPueblos.description,
                date: vocesPueblos.date,
                link: vocesPueblos.link
            });
        }
    }, [isEditing, vocesPueblos]);

    const handleInputChange = (e) => {
        setInputVocesPueblos({
            ...inputVocesPueblos,
            [e.target.name]: e.target.value,
        });
    };

    const handleDateChange = (date, dateString) => {
        setInputVocesPueblos({
            ...inputVocesPueblos,
            date: dateString,
        });
    };

    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const validateFileType = (file) => {
        const allowedTypes = ["image/jpeg", "image/png", "image/webp", "image/jpg", "image/jfif"];
        if (!allowedTypes.includes(file.type)) {
            Swal.fire({
                icon: 'error',
                title: 'Archivo no válido',
                text: 'Solo se permiten archivos con extensiones jpg, jpeg, png, webp o jfif.',
            });
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('title', inputVocesPueblos.title);
        formData.append('description', inputVocesPueblos.description);
        formData.append('date', inputVocesPueblos.date);
        formData.append('link', inputVocesPueblos.link);
        if (fileList.length > 0) {
            formData.append('imageUrl', fileList[0].originFileObj);
        }

        try {
            if (isEditing) {
                await dispatch(updatePueblo(vocesPueblosId, formData));
                Swal.fire('Voces de los Pueblos actualizado', 'El contenido ha sido actualizado exitosamente', 'success');
            } else {
                await dispatch(postNewPueblo(formData));
                Swal.fire('Voces de los Pueblos creado', 'El contenido ha sido creado exitosamente', 'success');
            }
            setInputVocesPueblos({
                title: "",
                description: "",
                date: null,
                link: ""
            });
            setFileList([]);
        } catch (error) {
            console.error("Error posting new voces pueblos:", error);
        }
    };

    return (
        <div className="form-ant">
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                style={{ maxWidth: 600 }}
                onFinish={handleSubmit}
            >
                <h1>{isEditing ? 'Editar Voces de los Pueblos' : 'Crear Voces de los Pueblos'}</h1>

                <Form.Item label="Titulo">
                    <Input
                        onChange={handleInputChange}
                        name="title"
                        placeholder="Escriba el titulo"
                        value={inputVocesPueblos.title}
                    />
                </Form.Item>

                <Form.Item label="Imagen">
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleFileChange}
                        beforeUpload={validateFileType}
                    >
                        {fileList.length < 1 && (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        )}
                    </Upload>
                </Form.Item>

                <Form.Item label="Descripcion">
                    <Input.TextArea
                        rows={4}
                        onChange={handleInputChange}
                        name="description"
                        placeholder="Descripcion"
                        value={inputVocesPueblos.description}
                    />
                </Form.Item>

                <Form.Item label="Link">
                    <Input
                        onChange={handleInputChange}
                        name="link"
                        placeholder="Ingrese el link"
                        value={inputVocesPueblos.link}
                    />
                </Form.Item>

                <Form.Item label="Fecha">
                    <DatePicker
                        format="DD/MM/YYYY"
                        onChange={handleDateChange}
                        value={inputVocesPueblos.date ? moment(inputVocesPueblos.date, "DD/MM/YYYY") : null}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {isEditing ? 'Actualizar' : 'Guardar'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export { FormularioCrearVocesPueblos };
