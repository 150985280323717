import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWinningWorksByCategory } from '../../Redux/Actions/Index';
import Swal from 'sweetalert2';
import { Layout, Row, Col, Tabs, Collapse } from 'antd';
import './Concurso.css';
import collageConcurso from '../../components/Assets/collageConcurso.jpeg';

const { TabPane } = Tabs;
const { Content } = Layout;
const { Panel } = Collapse;

const Concurso = () => {
  const dispatch = useDispatch();
  const winningWorks = useSelector(state => state.allWorks);
  const [activeTab, setActiveTab] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  useEffect(() => {
    if (activeTab && selectedSubcategory) {
      let category;
      switch (activeTab) {
        case '2':
          category = 'Poesía';
          break;
        case '3':
          category = 'Cuento Breve';
          break;
        case '4':
          category = 'Fotografía';
          break;
        case '5':
          category = 'Dibujo';
          break;
        default:
          category = null;
          break;
      }
      dispatch(getWinningWorksByCategory(category, selectedSubcategory)).then((data) => {
        if (data.length === 0) {
          Swal.fire({
            title: 'Sin trabajos',
            text: `No hay trabajos en la categoría ${category} y subcategoría ${selectedSubcategory}`,
            icon: 'info',
            confirmButtonText: 'OK'
          });
        }
      });
    }
  }, [activeTab, selectedSubcategory, dispatch]);

  const handleTabChange = (key) => {
    setActiveTab(key);
    setSelectedSubcategory(null);
  };

  const handleSubcategorySelect = (subcat) => {
    setSelectedSubcategory(subcat);
  };

  const renderSubcategories = (categoryKey) => {
    if (categoryKey === '2' || categoryKey === '3' || categoryKey === '4' || categoryKey === '5') {
      return (
        <div className="subcategory-options">
          <p>Elige una subcategoría:</p>
          <ul>
            <li onClick={() => handleSubcategorySelect('Adulto')}>Adulto</li>
            <li onClick={() => handleSubcategorySelect('Adolescente')}>Adolescente</li>
            <li onClick={() => handleSubcategorySelect('Niño')}>Niño</li>
          </ul>
        </div>
      );
    }
    return null;
  };

  const renderWinningWorks = () => {
    return winningWorks.map(work => (
      <div key={work.id} className="work-item">
        <h3>{work.title}</h3>
        <img src={work.imageUrl} alt={work.title} className="work-image" />
        <p>{work.description}</p>
      </div>
    ));
  };

  return (
    <div className="concurso-container">
      <Layout>
        <Content>
          <Row justify="center">
            <Col span={16}>
              <Tabs activeKey={activeTab} onChange={handleTabChange} centered>
                <TabPane tab="Bases y Condiciones" key="1">
                  <div className="concurso-content">
                    <h2 className="section-title">Concurso Nacional de Poesía, Cuento Breve, Fotografía y Dibujo</h2>
                    <p>
                      Está abierta la inscripción para el 33 a. Concurso Nacional Literario y Artístico LA IDEA 110º ANIVERSARIO para Niños/as, Adolescentes y Adultos, de Poesía, Cuento Breve, Fotografía y Dibujo.
                    </p>
                    <p>
                      Fotografía y Dibujo para Adultos (18 años de edad en adelante), Adolescentes (12 a 17 años de edad) y Niños/as (8-11 años de edad).
                    </p>
                    <p>
                      La participación es totalmente libre, debiendo el participante adulto abonar $ 2.500 (los suscriptores $ 2.000) por derecho de inscripción; adolescentes y niños/as SIN CARGO. Los trabajos deben ser originales y de creación propia.
                    </p>
                    <p>
                      En Fotografía, las medidas no superarán los 20 x 25 cm., presentándose 3 ORIGINALES (NO FOTOCOPIAS; los trabajos presentados en fotocopia quedarán fuera de concurso) en color o blanco y negro.
                    </p>
                    <p>
                      En Dibujo, la medida no podrá superar la hoja de oficio, presentándose original y dos copias, en tinta, acompañados de una hoja con la explicación del motivo buscado y su título.
                    </p>
                    <p>
                      En las 2 disciplinas los participantes podrán presentar hasta 3 trabajos cada uno, debiendo (los adultos) abonar por cada uno. Los mismos pueden ser entregados personalmente por la escuela o retirados de la misma ya que los/as ganadores/as serán utilizados en la premiación para su exposición.
                    </p>
                    <p>
                      Cuentos y Poesías serán entregados a la casilla de correo electrónico.
                    </p>
                    <p>
                      Todos los trabajos presentados deberán estar acompañados por los datos del autor/a.
                    </p>
                    <p>
                      Para mayor información, los interesados ​​deberán comunicarse al teléfono: (15) 5064-6321 en el horario de 9 a 19hs; o vía mail a periodicolaidea1915@gmail.com; o visitando nuestro Portal: www.multimedioslaidea.com.ar
                    </p>
                    <p>
                      NOTA: TODOS LOS TRABAJOS DEBERÁN SER INDIVIDUALES. LOS ORIGINALES NO SERÁN DEVUELTOS. FECHA DE CIERRE DE INSCRIPCIÓN 18 DE JULIO.
                    </p>
                  </div>
                  <h3 className="section-title">Preguntas Frecuentes</h3>
                  <Collapse>
                    <Panel header="¿Cuáles son los requisitos para participar en el concurso?" key="1">
                      <p>Los requisitos varían según la categoría de participación. Por favor, consulta las bases del concurso para más detalles.</p>
                    </Panel>
                    <Panel header="¿Cuál es la fecha límite para enviar los trabajos?" key="2">
                      <p>La fecha límite para la recepción de trabajos es el 19 de julio de 2024.</p>
                    </Panel>
                    <Panel header="¿Cómo puedo obtener más información sobre el concurso?" key="3">
                      <p>Para más información, puedes comunicarte las 24 horas al teléfono: (15) 5064-6321; o vía mail a mensajes@multimedioslaidea.com.ar; o visitando nuestro Portal: www.multimedioslaidea.com.ar</p>
                    </Panel>
                  </Collapse>
                </TabPane>
                <TabPane tab="Poesía" key="2">
                  {renderSubcategories('2')}
                  {selectedSubcategory && (
                    <div>
                      <p>Contenido de Poesía - {selectedSubcategory}...</p>
                      {renderWinningWorks()}
                    </div>
                  )}
                </TabPane>
                <TabPane tab="Cuento Breve" key="3">
                  {renderSubcategories('3')}
                  {selectedSubcategory && (
                    <div>
                      <p>Contenido de Cuento Breve - {selectedSubcategory}...</p>
                      {renderWinningWorks()}
                    </div>
                  )}
                </TabPane>
                <TabPane tab="Fotografía" key="4">
                  {renderSubcategories('4')}
                  {selectedSubcategory && (
                    <div>
                      <p>Contenido de Fotografía - {selectedSubcategory}...</p>
                      {renderWinningWorks()}
                    </div>
                  )}
                </TabPane>
                <TabPane tab="Dibujo" key="5">
                  {renderSubcategories('5')}
                  {selectedSubcategory && (
                    <div>
                      <p>Contenido de Dibujo - {selectedSubcategory}...</p>
                      {renderWinningWorks()}
                    </div>
                  )}
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Content>
      </Layout>

      {!activeTab && (
        <div className="header-image">
          <img src={collageConcurso} alt="Concurso Nacional" className="large-image" />
        </div>
      )}
    </div>
  );
}

export default Concurso;
