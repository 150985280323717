import {
    POST_NEW_NOTICE,
    POST_NEW_PROGRAM,
    POST_NEW_WORK,
    GET_NOTICES,
    GET_NOTICE,
    UPDATE_NOTICE,
    GET_PROGRAMS,
    GET_PROGRAM,
    GET_WINNING_WORKS,
    ADMIN_LOGIN_SUCCESS,
    GET_PUEBLOS,
    GET_PUEBLO,
    POST_NEW_PUEBLO,
    UPDATE_PUEBLO
} from "../Actions/Types";

const initialState = {
    allNotices: [],
    allPrograms: [],
    allWorks: [],
    programDetail: null,
    noticeDetail: null,
    workDetail: null,
    puebloDetail: null,
    isAuthenticated: false,
    allPueblos: []
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTICES:
            return {
                ...state,
                allNotices: action.payload
            }
        case GET_NOTICE:
            return {
                ...state,
                noticeDetail: action.payload
            }
        case POST_NEW_NOTICE:
            return {
                ...state,
                allNotices: [...state.allNotices, action.payload]
            }
        case UPDATE_NOTICE:
            return {
                ...state,
                allNotices: state.allNotices.map(notice =>
                    notice.id === action.payload.id ? action.payload : notice
                ),
                noticeDetail: action.payload
            }
        case POST_NEW_PROGRAM:
            return {
                ...state,
                allPrograms: [...state.allPrograms, action.payload]
            }
        case POST_NEW_WORK:
            return {
                ...state,
                allWorks: [...state.allWorks, action.payload]
            }    
        case GET_PROGRAMS:
            return {
                ...state,
                allPrograms: action.payload
            }
        case GET_PROGRAM:
            return {
                ...state,
                programDetail: action.payload
            }
        case GET_WINNING_WORKS:
            return {
                ...state,
                allWorks: action.payload,
            };        
        case ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
            };
        case GET_PUEBLOS:
            return {
                ...state,
                allPueblos: action.payload
            };
        case POST_NEW_PUEBLO:
            return {
                ...state,
                allPueblos: [...state.allPueblos, action.payload]
            };
            
        case GET_PUEBLO:
            return {
                ...state,
                puebloDetail: action.payload
            };
        case UPDATE_PUEBLO:
            return {
                ...state,
                allPueblos: state.allPueblos.map(pueblo =>
                    pueblo.id === action.payload.id ? action.payload : pueblo
                )
            };
        default:
            return state;
    }
}

export default rootReducer;