import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getPueblos } from '../../Redux/Actions/Index'
import moment from 'moment'
import PaginationComponent from '../../components/Pagination/Pagination'
import './Pueblos.css'


const Pueblos = () => {
    const dispatch = useDispatch()
    const pueblos = useSelector((state) => state.allPueblos)
    const isAuthenticated = useSelector((state) => state.isAuthenticated)

    const [currentPage, setCurrentPage] = useState(1)
    const pueblosPerPage = 10
    const totalPages = Math.ceil(pueblos.length / pueblosPerPage)

    useEffect(() => {
        dispatch(getPueblos())
    }, [dispatch])

    const sortedPueblos = pueblos.slice().sort((a, b) => {
        const dateA = moment(a.date, 'DD-MM-YYYY')
        const dateB = moment(b.date, 'DD-MM-YYYY')
        return dateB - dateA
    })

    const indexOfLastPueblo = currentPage * pueblosPerPage
    const indexOfFirstPueblo = indexOfLastPueblo - pueblosPerPage
    const currentPueblos = sortedPueblos.slice(indexOfFirstPueblo, indexOfLastPueblo)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    return (
        <Container className="pueblos-container">
            <Row className="pueblos-list-section">
                <Col md={12}>
                    <h2>Voces de los Pueblos</h2>
                    {currentPueblos && currentPueblos.length > 0 ? (
                        console.log("Datos de pueblos:", currentPueblos),
                        currentPueblos.map((pueblo, index) => {
                            const formattedDate = pueblo.date
                                ? moment(pueblo.date, 'DD-MM-YYYY').format('DD/MM/YYYY')
                                : "Fecha no disponible"

                            return (
                                <Card key={index} className="pueblo-card mb-4">
                                    <Card.Body>
                                        <p>{pueblo.title}</p>
                                        <img src={pueblo.imageUrl} alt={pueblo.title} className="img-fluid mb-3" />
                                        <Card.Text>{pueblo.description}</Card.Text>
                                        <Card.Text>
                                            <a href={pueblo.link} target="_blank" rel="noopener noreferrer">
                                                {pueblo.link}
                                            </a>
                                        </Card.Text>
                                        <Card.Footer className="text-muted">
                                            <small>{formattedDate}</small>
                                        </Card.Footer>
                                        {isAuthenticated && (
                                            <Link to={`/editar-pueblo/${pueblo.id}`}>
                                                <Button variant="primary">Editar Pueblo</Button>
                                            </Link>
                                        )}
                                    </Card.Body>
                                </Card>
                            )
                        })
                    ) : (
                        <p>No hay pueblos disponibles.</p>
                    )}
                </Col>
            </Row>
            <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </Container>
    )
}

export default Pueblos